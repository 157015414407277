.App {
  text-align: left;
  margin: 40px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  /* min-height: 1vh; */
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(32px + 2vmin);
  color: green;
  font-weight: bold;
  font-family: source-code-pro,'Courier New', Menlo, Monaco, Consolas, 
    monospace;
}

.App-link {
  color: #006100;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
